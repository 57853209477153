<template>
<div class="col-md-8 mx-auto">
  <div class="card rounded shadow-lg">
    <div class="card-body">
      <div class="card-title text-center">
        <h1>Documentos no relacionados con tablas</h1>
      </div>
      <br>
      <div class="row">
        <div class="">
          <div style="text-align:center">
            <v-progress-circular
              v-if="showProgress"
              :size="50"
              :width="5"
              color="blue"
              indeterminate
            ></v-progress-circular>
          </div>
          <br>
          <v-spacer></v-spacer>
          <!--
          <v-text-field
            v-model="search_items"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        -->
          <v-data-table
            :headers="headers"
            :items="items"
            :pagination.sync="pagination"
            :search="search_items"
            select-all
            item-key="_id"
            :loading="false"
            class="elevation-1"
          >
            <template slot="headers" slot-scope="props">
              <tr>
                <th>
                  <input type="checkbox" v-model="allChecked" v-on:click="checkAllItems">
                </th>
                <th></th>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                  @click="changeSort(header.value)"
                >
                  <v-icon small>arrow_upward</v-icon>
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
              <td>
                <input type="checkbox" v-bind:value="props.item._id" v-model="delItems">
              </td>
              <td><a href="#" v-on:click.prevent="editItem(props.item._id)"><edit-icon class="custom-class"></edit-icon></a></td>
              <td>{{ props.item._id}}</td>
              <td>{{ props.item.tpocor_docs[0].tcr_nmbre }}</td>
              <td>{{ props.item.dcm_ano }}</td>
              <td>{{ props.item.dcm_cdgo }}</td>
            </template>
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
              Su búsqueda de "{{ search_items }}" no tuvo resultados.
            </v-alert>
          </v-data-table>
          <br>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
//import { VIcon, VProgressLinear, VProgressCircular, VSpacer, VDataTable, VAlert } from 'vuetify/lib';
import { EditIcon } from 'vue-feather-icons';

export default {
  components: {
    //VIcon, VProgressLinear, VProgressCircular, VSpacer, VDataTable, VAlert,
    EditIcon
  },
  data(){
    return{
      items: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      showProgress: false,
      search_items: '',
      pagination: {
        sortBy: 'name'
      },
      headers: [
        { text: 'Id', value: '_id' },
        { text: 'Tipo de documento', value: 'tcr_id' },
        { text: 'Año', value: 'dcm_ano' },
        { text: 'Consecutivo', value: 'dcm_cdgo' }
      ]
    }
  },
  computed: {
    checkedItems(){
      if (this.delItems.length > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function() {
    //feather.replace();
  },
  created: function()
  {
    this.searchItems({ cmp_id: this.$store.state.company });
  },
  methods: {
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    checkAllItems(){
      this.delItems = [];
      if (!this.allChecked){
        for (var i = 0; i < this.items.length; i++){
          this.delItems.push(this.items[i]._id);
        }
      }
    },
    newSearch () {
      this.search = {};
    },
    searchItems(p){
      if (p != null){
        this.showProgress = true;
        this.debug = p;

        p.cmp_id = String(this.$store.state.company);
        this.message = 'Buscando documentos';
        let uri = '/docs/notintablas';
        this.axios.post(uri, p)
        .then((response) => {
          this.items = response.data;
          this.showProgress = false;
          this.delItems = [];

        })
        .catch(err => {
          console.log(err);
          this.showProgress = false;
          this.message = '¡Error al buscar los documentos' + err;
          if(err.response.status === 401) {
            this.$router.push({ name: '/' });
          }
        });
      }
    },
    editItem(id){
      this.$router.replace({ name: 'CreateDoc', params: { id: id, add: false, allowModifyDoc: true } });
    }
  }
}
</script>
